<template>
    <div>
        <div class="leads">
            <div class="leads__search">
                <input
                        class="leads__search-input"
                       type="text"
                       v-model="search"
                       placeholder="Поиск"
                >
                <button class="leads__search-btn"
                        v-if="btnVisible"
                        @click="search = ''"
                >Очистить</button>
            </div>

            <div class="leads__block" v-if="filteredLeads.length > 0">
                <div class="leads__item" v-for="lead in filteredLeads" :key="lead.id">
                    <div class="leads__item-descr">
                        <div class="leads__item-descr-title">
                            <h3 class="leads__item-descr-title-name">{{ lead.title }}</h3>
                            <div class="leads__item-descr-title-date">
                                <span>Дата создания: {{ lead.date }}</span>
                                <small v-if="lead.date_update">Последние изменения: {{ lead.date_update }}</small>
                            </div>
                        </div>
                        <div class="leads__item-descr-info" v-if="lead.address">
                            <span>{{ lead.address }}</span>
                        </div>
                    </div>
                    <div class="leads__item-btns">
                        <button class="btn save" @click="open(lead.id)">Посмотреть</button>
                        <button class="btn danger" @click="showModal(lead.id, lead.title)">Удалить</button>
                    </div>
                </div>

                <modal-delete-lead
                        v-if="openedModal"
                        :leadName="leadName"
                        :leadId="leadId"
                        @close="closeModal"
                />
            </div>

            <div v-else class="leads__block">
                <h2 class="section-title">Сделок не найдено</h2>
            </div>
        </div>
    </div>
</template>

<script>
    import ModalDeleteLead from "../components/ModalDeleteLead";

    export default {
        components: {ModalDeleteLead},
        data() {
            return {
                search: '',
                leadName: '',
                leadId: null,
                openedModal: false
            }
        },
        mounted () {
            this.$store.dispatch('leads/loadLeads')
        },
        computed: {
            btnVisible() {
                return this.search.length > 0
            },
            leads () {
                return this.$store.getters['leads/leads']
            },
            filteredLeads() {
                return this.leads.filter(item => {
                    return item.title.toLowerCase().indexOf(this.search.toLowerCase()) > -1 || item.title.toLowerCase().indexOf(this.search.toLowerCase()) > -1
                })
            }
        },
        methods: {
            open(id) {
                this.$router.push(`/lead/${id}`)
            },
            showModal(id, name) {
                this.leadId = id
                this.leadName = name
                this.openedModal = true
            },
            closeModal() {
                this.leadId = null
                this.leadName = ''
                this.openedModal = false
            }
        }
    }
</script>

<style lang="scss">
    @import '../style/colors.scss';
    .leads {
        width: 600px;
        &__item {
            width: 100%;
            box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%);
            background: #fff;
            margin-bottom: 20px;
            &:last-of-type {
                margin-bottom: 0;
            }
            &-descr {
                border-bottom: 1px solid #d3d3d3;
                padding: 20px;
                background: #fbfbfb;
                &-title {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    &-name {
                        font-size: 24px;
                        font-weight: 500;
                        margin-right: 15px;
                    }
                    &-date {
                        &>small {
                            display: block;
                            margin-top: 5px;
                        }
                    }
                }
                &-info {
                    margin-top: 10px;
                    font-size: 14px;
                }
            }
            &-btns {
                padding: 20px;
                &>.save {
                    margin-right: 20px;
                }
            }
        }
        &__search {
            display: flex;
            justify-content: space-between;
            align-items: stretch;
            margin-bottom: 20px;
            border-bottom: 1px solid #999;
            min-height: 35px;
            padding-bottom: 10px;
            &-btn {
                width: auto;
                height: auto;
                background: transparent;
                padding: 6px 10px;
                font-size: 12px;
                font-weight: 500;
                text-transform: uppercase;
                color: $danger;
                border: 1px solid $danger;
                transition: .3s;
                margin-left: 10px;
                &:hover {
                    background: lighten($danger, 43%);
                }
            }
        }
    }
</style>
